import React from "react";
import { css } from "@emotion/core";
import { font, color } from "../constants/styles";

const container = css`
  font-size: ${font.textSize};
  color: ${color.white};
  background-color: ${color.pink};
`;

const textArea = css`
  width: 90%;
  margin: 0 auto;
  padding: 160px 0;
`;

const text = css`
  padding-top: 48px;
`;

const title = css`
  padding-top: 24px;
  font-size: ${font.titleSize};
`;

// 現在準備中のためコメントアウト
// const link = css`
//   color: ${color.white};
//   :hover {
//     opacity: 0.7;
//   }
// `;

export const MajorTradingManufacturerText = () => {
  return (
    <section css={container}>
      <div css={textArea}>
        <p>MAJOR TRADING MANUFACTURER</p>
        <h3 css={title}>主要取引メーカー</h3>
        <p css={text}>●現在準備中</p>
        {/* 準備中から変更になれば下記をご使用ください */}
        {/* <p css={text}>
          <a href="#" css={link}>
            ●現在準備中
          </a>
        </p> */}
      </div>
    </section>
  );
};
