import React from "react";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { MajorTradingManufacturerText } from "../components/MajorTradingManufacturerText";

export default function MajorTradingManufacturer() {
  const title = "主要取引メーカー";

  return (
    <Layout>
      <Head props={title} />
      <MajorTradingManufacturerText />
    </Layout>
  );
}
